import React from "react";

// tailwind styles
import "../styles/main.css";

import { ToastProvider } from "react-toast-notifications";
import { ThemeProvider } from "../contexts/ThemeContext/ThemeContext";
import { DefaultSeo } from "next-seo";
import SEO from "../next-seo.config";

//progress bar
import "nprogress/nprogress.css";

import ErrorBoundary from "../components-reusable/Errors/ErrorBoundary/ErrorBoundary";
import { AnalyticsProvider } from "../contexts/Segment/Segment";
import RouterListener from "../components-reusable/App/RouterListener/RouterListener";

const MyApp = ({ Component, pageProps }) => {
  return (
    <ToastProvider
      placement="bottom-left"
      autoDismiss
      ref={typeof window !== `undefined` && window.__react_toast_provider}
    >
      <ThemeProvider>
        <AnalyticsProvider writeKey={process.env.NEXT_PUBLIC_SEGMENT_API_KEY}>
          <ErrorBoundary>
            <RouterListener>
              <DefaultSeo {...SEO} />
              <Component {...pageProps} />
            </RouterListener>
          </ErrorBoundary>
        </AnalyticsProvider>
      </ThemeProvider>
    </ToastProvider>
  );
};

export default MyApp;
