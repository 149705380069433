export default {
  title: "Tap For Service | Online Booking Made Easy",
  description:
    "Accepting appointments and bookings online for your business should be easy. With Tap For Service, it finally is. No code required.",
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://tapforservice.com/",
    site_name: "Tap For Service",
    images: [{ url: "https://tapforservice.com/images/og_image.png" }],
  },
  twitter: {
    cardType: "summary_large_image",
  },
};
